
export const OurPricingData= [
  {
    id: 1,
    title: "S.JEEVES",
    newPrice: "$TBC",
    oldPrice: "$TBC",
    subTitle: "MVP in Progress",
    contain1: "Facilitate scrum agile ceremonies and guide scrum teams to follow Agile and Scrum practices",
    contain2: "Keep track of blockers and project finances ",
    contain3: "Schedule connects with relevant parties to resolve blockers",
    contain4: "Guide scrum teams to follow Agile and Scrum practices",
    contain5: "Served as Teams, Google Workspace and Slack App ",
    contain6: "Send updates/actions from a meeting "    
  },
{
    id: 2,
    title: "DVS Invest Assist",
    newPrice: "TBC",
    oldPrice: "TBC",
    subTitle: "Ideation Stage",
    contain1: "Scenario Analysis – Empower portfolio managers to explore and define new scenarios seamlessly using natural language.",
    contain2: "Hyper personalized campaigns and proposals design and tracking",
    contain3: "100X relationship management ",
    contain4: "Preliminary research on interest list and sentiment analysis",
    contain5: "",
    contain6: ""   
},

{
    id: 3,
    title: "LUMINO",
    newPrice: "$200",
    oldPrice: "$250",
    subTitle: "MVP Ready",
    contain1: "Exploratory Career Counselling helping Career Counsellors scale",
    contain2: "Anytime counselling for both parents and child",
    contain3: "Instant counselling report as a PDF with suggested career paths ",
    contain4: "Support for secondary and higher secondary students counselling. Grads counselling by Jan 2025",
    contain5: "Hosing (1 year Free)",
    contain6: "Domain (1 Year Free)"
},


 
];
export default OurPricingData;