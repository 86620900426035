import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";

import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="titleFont">
        <h2>DVSSPL Privacy Policy</h2>
        <p>
          At DVSSPL (Digital Virtual Staffing Solutions Pvt Ltd), we are
          committed to protecting your privacy. This Privacy Policy explains how
          we collect, use, and disclose your personal information when you use
          our services, including LLMs by various providers, Google Sign-On, and
          Azure Cloud.
        </p>

        <h3>Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Information that identifies
            you, such as your name, email address, and age and any other
            relevant info needed to assess better career options for your child
            or you.
          </li>
          <li>
            <strong>Usage Information:</strong> Information about how you use
            our services, including your interactions with the large language
            models and the data shared with these LLMs will be governed as per
            the data privacy guidelines of these LLMs.
          </li>
          <li>
            <strong>Device Information:</strong> Information about the device
            you use to access our services, such as your IP address and browser
            type.
          </li>
        </ul>

        <h3>How We Use Your Information</h3>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Respond to your inquiries and provide customer support.</li>
          <li>Personalize your experience.</li>
          <li>Communicate with you about updates and promotions.</li>
        </ul>

        <h3>Sharing Your Information</h3>
        <p>We may share your information with:</p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Third-party vendors who help us
            operate our services, including Google and Microsoft Azure.
          </li>
          <li>
            <strong>Legal Requirements:</strong> If required by law or to
            protect our rights and safety.
          </li>
        </ul>

        <h3>Data Security</h3>
        <p>
          We take reasonable measures to protect your information from
          unauthorized access, use, or disclosure. However, no internet
          transmission is completely secure, and we cannot guarantee the
          absolute security of your data.
        </p>

        <h3>Children's Privacy</h3>
        <p>
          We do not knowingly collect personal information from children under
          13 without parental consent. If we become aware that we have collected
          such information, we will take steps to delete it.
        </p>

        <h3>Your Rights</h3>
        <p>
          You have the right to access, correct, or delete your personal
          information. You may also object to or restrict our processing of your
          information. To exercise these rights, please contact us at
          privacy@dvs.solutions
        </p>

        <h3>Compliance with Indian Data Protection Laws</h3>
        <p>
          We comply with the Information Technology (Reasonable Security
          Practices and Procedures and Sensitive Personal Data or Information)
          Rules, 2011. We take all reasonable measures to protect sensitive
          personal data or information (SPDI) from unauthorized access, damage,
          use, or disclosure.
        </p>

        <h3>International Data Transfers</h3>
        <p>
          Your personal information may be transferred to and processed in
          countries other than India. We ensure that such transfers comply with
          applicable data protection laws and that your information is
          adequately protected.
        </p>

        <h3>Use of Google Sign-On</h3>
        <p>
          If you use Google Sign-On to access our services, we collect the
          information you provide to Google and use it in accordance with this
          Privacy Policy. Google’s handling of your information is governed by
          Google’s Privacy Policy.
        </p>

        <h3>Use of Azure Cloud</h3>
        <p>
          Our services are hosted on Microsoft Azure Cloud. Your data may be
          stored and processed on servers located outside India. We ensure that
          Microsoft Azure complies with applicable data protection regulations
          and provides adequate protection for your information.
        </p>

        <h3>Parental Consent and Acknowledgment</h3>
        <p>
          If you are under 18 years old, you must have obtained parental or
          guardian consent to use our services. By using our services, you
          acknowledge that you will use them solely for career counseling
          purposes and not for any other activities.
        </p>

        <h3>Changes to Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on our website, and your continued use of our services after
          such changes have been posted constitutes your acceptance of the new
          Privacy Policy.
        </p>

        <h3>Contact Information</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at privacy@dvs.solutions{" "}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
