import React from 'react'
import '../../../theme/css/Portfolio.css'
import PortfolioImg from '../Portfolio/PortfolioImg';
import { Link } from "react-router-dom";

export default function ScrumMaster() {
    return (
        <>

        <div className="banner banner-details">
        <div className="banner-all-details">
          <div className="text-width">
            <h4>S.JEEVES - AI Powered DVS Scrum Master </h4>
            <h6>&nbsp;</h6>
          </div>
        </div>
      </div>

     <div className="container">

      <div className="aboutus spacing-1">
        <button className=" button">
          <Link to="https://vimeo.com/1022959342">
            {" "}
            Watch Demo{" "}
          </Link>
        </button>
      </div>

      <div className="titleFont spacing-1">
        <h3>About S.JEEVES</h3>
        <p className="paragraph">
          S.JEEVES is an AI-powered digital virtual assistant
          designed to run agile ceremonies for the team, taking actions for blockers and tracking them, updating project management tracker.
          S.JEEVES is integrated with common project management trackers like JIRA and your document repository e.g. Confluence and Sharepoint.
          It can be deployed as a Teams App, Google Workspace App or Slack to provide seamless user experience.
          It trains itself every day on the incremental communication and acts as a Assistance discharging all the servant duties for project, product leaders.


        </p>

        <p className="paragraph">
          S.JEEVES aims to provide following value propositions:


        </p>

        <ul className="paragraph">
            <li>
              {" "}
              Time to Hire - No need to wait for months to get a Scrum Master hired. 
            </li>
            <li>
              {" "}
              Retain knowledge - No need to worry about the knowledge retention when the Scrum Master leaves the team.
            </li>
            <li>
              {" "}
              Streamline process - Helps streamline processes with the vast knowledge base it has been trained on. It can be further fine-tuned to suit custom processes.
            </li>
            <li>
              {" "}
              Leave all the administrative work to S.JEEVES and Tech Leads / Product Owner focus on the product development.
            </li>
          </ul>

        </div>

        <div className="titleFont spacing-1" >
          <h3>S.JEEVES Key Capabilities </h3>
        </div>
        <PortfolioImg/>
        </div>
      </>
    )
}
