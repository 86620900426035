import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import AboutSlider from "./AboutSlider";
function HomeFAQ() {
  return (
    <>
      <div className="container">
        <div className="spacing">
          <Row className="homeFAQRow">
            <Col xl={6} lg={6} md={6}>
              <div className="titleFont">

                <h2>Why Choose a Digital Virtual Staff</h2>
              </div>
              <div className="homeFAQ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Time to Fill a Role</Accordion.Header>
                    <Accordion.Body>
                      Process of filling roles within
                      organizations typically takes 2 to 4 months, delaying
                      productivity. Moreover, the elimination of bench strengths
                      has prolonged even contract staff hiring processes.
                      However, Digital Virtual Staff (DVS) solutions offer a
                      swift integration post-initial setup, enabling efficient
                      handling of tasks and contributing to streamlined
                      operations and heightened productivity within
                      organizations
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Knowledge Retention</Accordion.Header>
                    <Accordion.Body>
                      Organizations often face the challenge of losing valuable
                      human staff to competitors, resulting in the loss of
                      accumulated knowledge and expertise. With traditional
                      employees, knowledge retention can be precarious as
                      individuals depart. However, with Digital Staff solutions,
                      organizations can retain full control over the knowledge
                      acquired by their staff. These AI-powered systems can
                      capture, store, and utilize knowledge consistently,
                      ensuring that critical information remains within the
                      organization's possession even as human employees come and
                      go. This ability to preserve institutional knowledge
                      enhances continuity, facilitates smoother transitions, and
                      strengthens the organization's long-term resilience and
                      competitiveness in the market.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>24 X 7 Availablity</Accordion.Header>
                    <Accordion.Body>
                      Digital Staff, available around the clock, can alleviate
                      the burden on human employees, enhancing their well-being
                      by reducing stress and burnout while boosting
                      organizational productivity. This balance ensures
                      organizational priorities are met while supporting the
                      health and happiness of staff.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Repeatative Admin and Mundane work
                    </Accordion.Header>
                    <Accordion.Body>
                      Repetitive and mundane administrative tasks inherent in
                      many organizational roles can quickly disillusion bright
                      minds. While bots can handle transactional tasks
                      efficiently, they lack the cognitive flexibility of human
                      thought. Digital Staff, powered by AI, possesses both
                      short-term and long-term memory capabilities, enabling it
                      to not only perform tasks but also provide guidance to
                      human staff. This combination of automation and cognitive
                      abilities makes Digital Staff invaluable in augmenting
                      human capabilities within organizations, freeing up
                      talented individuals to focus on more complex and
                      strategic initiatives.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6}>
              <AboutSlider />
              {/* <img className="img-fluid mx-auto" alt="AboutUs" src={FAQ} /> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HomeFAQ;
