import team1 from '../../../assets/Images/Team/digant.jpg';
import team2 from '../../../assets/Images/Team/DigiStaff.jpg';
import team3 from '../../../assets/Images/Team/Mradul.jpg';
import team4 from '../../../assets/Images/Team/NIkhil.jpg';
import team5 from '../../../assets/Images/Team/Manisha.jpg';

export const OurTeamImg = [
  {
    id: 1,
    image: team1,
    title: "Digant Singh",
    subtitle: "Founder, CEO",
    linkedin: "https://www.linkedin.com/in/digant-singh/",
  },
  {
    id: 3,
    image: team3,
    title: "Mradul Singh",
    subtitle: "AI Advisor",
    linkedin: "https://www.linkedin.com/in/mradul-veer-singh-a182b221/",
  },
  {
    id: 4,
    image: team4,
    title: "Nikhil Koshi",
    subtitle: "Engineering Lead",
    linkedin: "https://www.linkedin.com/in/nikhil-john-koshi-902059b4/",
  },
  {
      id: 5,
      image: team2,
      title: "DVS",
      subtitle: "Digital Virtual Staff",
      linkedin: "https://www.linkedin.com/company/dvs-solutions-tech/",

  },
   {
     id: 2,
     image: team5,
     title: "Manisha Verma",
     subtitle: "Director",
   },
  // {
  //   id: 6,
  //   image: team2,
  //   title: "TBH",
  //   subtitle: "TBH",
  // },
  // {
  //   id: 7,
  //   image: team3,
  //   title: "TBH",
  //   subtitle: "TBH",
  // },
  // {
  //   id: 8,
  //   image: team4,
  //   title: "TBH",
  //   subtitle: "TBH",
  // },
];
export default OurTeamImg;