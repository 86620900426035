import { useState } from "react";
import "../../../theme/css/Portfolio.css";
import { Card } from "react-bootstrap";
import Portfolio1 from "../../../assets/Images/Portfolio/Portfolio1.png";
import Portfolio2 from "../../../assets/Images/Portfolio/Portfolio2.png";
import Portfolio3 from "../../../assets/Images/Portfolio/Portfolio3.png";
import Portfolio4 from "../../../assets/Images/Portfolio/Portfolio4.png";
import Portfolio5 from "../../../assets/Images/Portfolio/Portfolio5.png";
import Portfolio6 from "../../../assets/Images/Portfolio/Portfolio6.png";
import Portfolio7 from "../../../assets/Images/Portfolio/Portfolio7.png";
import Portfolio8 from "../../../assets/Images/Portfolio/Portfolio8.png";
import Portfolio9 from "../../../assets/Images/Portfolio/Portfolio9.png";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Row, Col } from "react-bootstrap";
function PortfolioImg() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);
  const [toggleState, setToggleState] = useState(3);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="container portfolio">
      <div className="bloc-tabs ">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Daily Scrum Call
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          Retrospectives
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          Sprint Planning
        </button>
        <button
          className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(4)}
        >
          Backlog Grooming
        </button>
        <button
          className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(5)}
        >
          Agile Coaching
        </button>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio1} />
                  </div>
                  <Card.Body>
                    <Card.Title>Daily Scrum Call</Card.Title>
                    <Card.Text>
                      S.JEEVES can run daily scrum call, take notes,
                      update jira and send summary to the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio2} />
                  </div>
                  <Card.Body>
                    <Card.Title>Retrospectives</Card.Title>
                    <Card.Text></Card.Text>
                    Leave your retrospective meeting to S.JEEVES. Not
                    only, it will remember the full context from the sprint, it
                    will facilitate the meeting, take actions and continue to
                    track them in future sprints.
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio3} />
                  </div>
                  <Card.Body>
                    <Card.Title>Backlog Grooming</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to groom your backlog,
                      ensure that acceptance criteria are defined and stories
                      written in a way that they can be understood by the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio4} />
                  </div>
                  <Card.Body>
                    <Card.Title>Sprint Planning</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to plan your sprint, ensure
                      that the team is not over committing and that the stories
                      are well defined.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio5} />
                  </div>
                  <Card.Body>
                    <Card.Title>Agile Maturity Assessment</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to assess your agile
                      maturity level and provide you with a roadmap to improve
                      it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio2} />
                  </div>
                  <Card.Body>
                    <Card.Title>Retrospectives</Card.Title>
                    <Card.Text></Card.Text>
                    Leave your retrospective meeting to S.JEEVES. Not
                    only, it will remember the full context from the sprint, it
                    will facilitate the meeting, take actions and continue to
                    track them in future sprints.
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio1} />
                  </div>
                  <Card.Body>
                    <Card.Title>Daily Scrum Call</Card.Title>
                    <Card.Text>
                      S.JEEVES can run daily scrum call, take notes,
                      update jira and send summary to the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio3} />
                  </div>
                  <Card.Body>
                    <Card.Title>Backlog Grooming</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to groom your backlog,
                      ensure that acceptance criteria are defined and stories
                      written in a way that they can be understood by the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio4} />
                  </div>
                  <Card.Body>
                    <Card.Title>Sprint Planning</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to plan your sprint, ensure
                      that the team is not over committing and that the stories
                      are well defined.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio5} />
                  </div>
                  <Card.Body>
                    <Card.Title>Agile Maturity Assessment</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to assess your agile
                      maturity level and provide you with a roadmap to improve
                      it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio4} />
                  </div>
                  <Card.Body>
                    <Card.Title>Sprint Planning</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to plan your sprint, ensure
                      that the team is not over committing and that the stories
                      are well defined.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio1} />
                  </div>
                  <Card.Body>
                    <Card.Title>Daily Scrum Call</Card.Title>
                    <Card.Text>
                      S.JEEVES can run daily scrum call, take notes,
                      update jira and send summary to the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio2} />
                  </div>
                  <Card.Body>
                    <Card.Title>Retrospectives</Card.Title>
                    <Card.Text></Card.Text>
                    Leave your retrospective meeting to S.JEEVES. Not
                    only, it will remember the full context from the sprint, it
                    will facilitate the meeting, take actions and continue to
                    track them in future sprints.
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio3} />
                  </div>
                  <Card.Body>
                    <Card.Title>Backlog Grooming</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to groom your backlog,
                      ensure that acceptance criteria are defined and stories
                      written in a way that they can be understood by the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio5} />
                  </div>
                  <Card.Body>
                    <Card.Title>Agile Maturity Assessment</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to assess your agile
                      maturity level and provide you with a roadmap to improve
                      it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={toggleState === 4 ? "content  active-content" : "content"}
        >
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio3} />
                  </div>
                  <Card.Body>
                    <Card.Title>Backlog Grooming</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to groom your backlog,
                      ensure that acceptance criteria are defined and stories
                      written in a way that they can be understood by the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio1} />
                  </div>
                  <Card.Body>
                    <Card.Title>Daily Scrum Call</Card.Title>
                    <Card.Text>
                      S.JEEVES can run daily scrum call, take notes,
                      update jira and send summary to the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio2} />
                  </div>
                  <Card.Body>
                    <Card.Title>Retrospectives</Card.Title>
                    <Card.Text></Card.Text>
                    Leave your retrospective meeting to S.JEEVES. Not
                    only, it will remember the full context from the sprint, it
                    will facilitate the meeting, take actions and continue to
                    track them in future sprints.
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio4} />
                  </div>
                  <Card.Body>
                    <Card.Title>Sprint Planning</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to plan your sprint, ensure
                      that the team is not over committing and that the stories
                      are well defined.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio5} />
                  </div>
                  <Card.Body>
                    <Card.Title>Agile Maturity Assessment</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to assess your agile
                      maturity level and provide you with a roadmap to improve
                      it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={toggleState === 5 ? "content  active-content" : "content"}
        >
          <div className="beffect">
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio5} />
                  </div>
                  <Card.Body>
                    <Card.Title>Agile Maturity Assessment</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to assess your agile
                      maturity level and provide you with a roadmap to improve
                      it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio1} />
                  </div>
                  <Card.Body>
                    <Card.Title>Daily Scrum Call</Card.Title>
                    <Card.Text>
                      S.JEEVES can run daily scrum call, take notes,
                      update jira and send summary to the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio2} />
                  </div>
                  <Card.Body>
                    <Card.Title>Retrospectives</Card.Title>
                    <Card.Text></Card.Text>
                    Leave your retrospective meeting to S.JEEVES. Not
                    only, it will remember the full context from the sprint, it
                    will facilitate the meeting, take actions and continue to
                    track them in future sprints.
                  </Card.Body>
                </Card>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio3} />
                  </div>
                  <Card.Body>
                    <Card.Title>Backlog Grooming</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to groom your backlog,
                      ensure that acceptance criteria are defined and stories
                      written in a way that they can be understood by the team.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <Card>
                  <div className="ba_beffect">
                    <Card.Img variant="top" src={Portfolio4} />
                  </div>
                  <Card.Body>
                    <Card.Title>Sprint Planning</Card.Title>
                    <Card.Text>
                      S.JEEVES can help you to plan your sprint, ensure
                      that the team is not over committing and that the stories
                      are well defined.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioImg;
